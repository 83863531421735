.searchInputs{
    display: flex;
    padding-bottom: 20px;
}
.search input{
    border:1px solid var(--primaryColor);
    border-radius: 2px;
    font-size:18px;
    padding:15px;
    height: 30px;
    width: 300px
}
.searchIcon{
    width:50px;
    background-color: var(--purpleColor);
    display:grid;
    place-items: center;
    color:#fff
}
input:focus{
    outline: none;
}

.dataResult{
    width:300px;
    height: auto;
    background: var(--primaryColor);
    box-shadow:rgba(0,0,0,0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
}
.dataResult::-webkit-scrollbar{
    display: none;
}
.dataResult .dataItem{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: var(--blackColor);
    text-decoration: none;
    font-size: 17px;
    font-family: var(--textFamily);
}
.dataResult .dataItem:hover{
    background:var(--purpleColor);
    color:#fff;
}
.dataItem p{
    padding-left: 5px;
}
#clearBtn{
    cursor:pointer
}
@media screen and (max-width: 760px){
    .search input{
        width: 250px
    }
}

@media screen and (max-width: 900px) and (min-width:760px){
    .search input{
        width: 170px
    }
}