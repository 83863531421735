/* blogpoststyle starts here */
.blogpageposts {
    padding-bottom: 20px;
    font-family: var(--textFamily)
}

.blogpagepostsrow {
    padding-bottom: 30px;
    background: #fff;
    padding-top: 30px;
}

.blogpageposts .blog-text {
    width: 90%;
}

.blogpageposts .blog-title {
    padding-bottom: 15px;
    color: var(--purpleColor);
    font-size: 27px;
    font-weight: 600;
    text-transform: capitalize;
}

.blog-title-details {
    position: relative;
    top: -15px;
    font-size: 14px;
    font-weight: 600
}

.blog-title-details span {
    color: var(--purpleColor);
}

.blog-readmore {
    font-family: var(--textFamily);
    font-size: 16px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: justify;
}

.blogposts-btn {
    text-align: right;
    padding-top: 20px
}

.blogpost-nav {
    color: #fff;
    text-decoration: none;
    font-family: var(--textFamily);
    font-size: 17px;
    padding: 8px 23px;
    border: none;
    border-radius: 5px;
    transition: 0.4s all ease;
    background-color: var(--purpleColor);
}

.blogpost-nav:hover {
    border: 2px solid var(--purpleColor);
    background: transparent;
    color: var(--primaryColor)
}

.blog-image img {
    width: 85%;
    max-height: 300px;
    object-fit: calc(100% - 100px);
    border-radius: 10px;
    transition: 0.4s all ease;
}

.blog-image:hover img {
    transform: scale(1.05);
}

.blog-date {
    background: var(--purpleColor);
    width: 90%;
    height: 80px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding-top: 10px
}

.blog-date-days {
    font-size: 25px;
}

.blog-date-monthyear {
    font-size: 16px !important;
}

.blog-podcast-audio {
    padding: 10px 0px 15px 0px;
    width: 100%;
    position: static;
}

.blog-podcast-audio audio::-webkit-media-controls-panel {
    width: 90% !important;
    background: var(--purpleColor) !important;
    color: #fff !important;

}

.blog-spotify-container {
    padding: 10px 0px;
    font-size: 17px;
    font-family: var(--textFamily);
    font-weight: 550;
    font-style: italic;
}

.blog-spotify-container a {
    color: var(--primaryColor);
    text-decoration: none;
    text-decoration: 0.4s all ease;
}

.blog-spotify-container a:hover {
    color: var(--purpleColor);
}

/* blogpost pagination starts here */
.paginationBttns {
    width: 80%;
    height: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
}

.paginationBttns a {
    padding: 7px;
    margin: 4px;
    border-radius: 5px;
    background: var(--primaryColor);
    text-decoration: none;
    color: var(--blackColor);
    font-family: var(--textFamily);
    cursor: pointer;
    transition: 0.4s all ease;
    font-size: 16px;
    font-weight: 500
}

.paginationBttns a:hover {
    background: var(--purpleColor);
    color: #fff;
}

.paginationActive a {
    background: var(--purpleColor);
    color: #fff;
}

/* blogpoststyle ends here */

/* blog sidebar starts here */
.blog-sidebar-title {
    font-family: var(--textFamily);
    font-size: 23px;
    color: var(--blackColor);
    padding-bottom: 15px;
    font-weight: 600
}

.sidebar-content {
    width: 95%;
    background: linear-gradient(180deg, rgba(193, 106, 211, 0.443), rgba(140, 77, 165, 0.443));
    margin-bottom: 25px;
}

.sidebar-content .sidebar-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.sidebar-content .blog-about-text {
    padding: 7px 0px 10px 10px;
    font-family: var(--cursiveFamily);
    color: #fff;
    font-size: 25px;
}

.blogabout-nav {
    text-decoration: none;
}

.blog-sidebar-list ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 25px;
}

.blog-sidebar-list ul li {
    padding-right: 20px;
}

.blog-sidebar-list .blog-connect {
    background: var(--backgroundColor);
    padding: 10px;
    border-radius: 50%;
    transition: 0.4s all ease;
}

.blog-sidebar-list .blog-connect:hover {
    background: var(--purpleColor);
    color: #fff;
}

.blog-sidebar-list .blog-connect:hover .connect-icon {
    color: #fff;
}

.blog-sidebar-list .connect-icon {
    color: var(--purpleColor);
    font-size: 22px;
    font-weight: bold;
    transition: 0.4s all color;
}

.sidebarposts {
    border-bottom: 1px solid rgb(192, 192, 192);
}

.sidebar-post {
    display: flex;
    padding: 10px 10px 2px 10px;
    color: var(--blackColor) !important;
    border: 1px solid rgb(192, 192, 192);
    border-bottom: none;

}

.sidebar-post img {
    width: 60px;
    height: 60px;
}

.sidebarposts-nav {
    text-decoration: none;
    transition: 0.4s all ease;
    opacity: 1;
    background: transparent;

}

.sidebarposts-nav:hover {
    opacity: 0.6;
}

.sidebar-postcontent {
    padding-left: 10px;
    color: var(--blackColor);
    font-size: 14px;
}

.sidebar-postcontent span {
    font-size: 18px;
    color: var(--purpleColor);
    font-family: var(--textFamily);
    font-weight: 550;
}

/* blog sidebar ends here */

/* Topblogs css starts here */
.topblog {
    margin: 30px 0;
    box-shadow: #c4c4c4 1px 1px 5px 5px;
    background: #fff;
    padding: 30px 5px;
}

.youtube-player {
    width: 100%;
}

.youtube-player .react-player {
    width: 100% !important;
}

.youtube-playlist {
    padding: 40px 0px 30px 0px;
    font-family: var(--textFamily);
    color: var(--blackColor);
    font-size: 21px;
    font-weight: 550
}

.youtube-playlist a {
    text-decoration: none;
    color: var(--primaryColor);
    transition: 0.4s all ease;
}

.youtube-playlist a:hover {
    color: var(--purpleColor)
}

.topblog-podcast-title {
    font-family: var(--textFamily);
    color: var(--blackColor);
    font-size: 20px;
    padding-bottom: 20px;
}

.topblog .audio {
    margin: 5px 0px;
    width: 100%;
}

.topblog-podcast {
    display: flex;
    margin-top: 15px;
}

.topblog-btn {
    color: #fff
}

.topblog-btn:hover {
    background: var(--primaryColor) !important;
    border: 0;
}