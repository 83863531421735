.video-container:before {
    /* content:'';
    position: absolute;
    width:100%;
    height: 350px;
    object-fit: cover;
    overflow: hidden */
    overflow: hidden;
    /* background: linear-gradient(180deg, rgba(19, 19, 18, 0.445), rgba(22, 22, 21, 0.438)) */
}

video {
    object-fit: contain;
    width: 100% !important;
    height: 350px;
    position: relative !important;
    overflow: hidden;

}

.video-text {
    width: 300px;
    position: absolute;
    top: 130px;
    text-align: left;
    right: 100px;
    opacity: 1;

}

.video-text .video-title {
    font-family: var(--textFamily);
    font-size: 30px;
    color: #fff;
}

.bg-button {
    margin: 30px 0px 30px 0px !important;
}

.bg-navlinkbtn {
    text-decoration: none;
    background: var(--purpleColor);
    color: #fff;
    padding: 15px 10px;
    border-radius: 4px;
    transition: 0.4s all ease;
}

.bg-navlinkbtn:hover {
    color: var(--purpleColor);
    background: #fff;
}

.second-eventbtn {
    margin-top: 60px;
    margin-bottom: 30px;
}

.bgnavlinkyellow {
    background: var(--primaryColor);
}

.bgnavlinkyellow:hover {
    background: #fff;
    color: var(--primaryColor)
}

.bgIconspan {
    padding: 20px 20px 20px 5px;
}

.bg-navlinkbtn span {
    padding: 20px 5px !important;
    text-transform: uppercase;
    font-size: 18px;
    font-family: var(--textFamily);
}

/* this for the event header */

.upcoming-event {
    padding: 10px 0px 20px 0px;
    background: var(--primaryColor);

}

.event-header {
    padding-bottom: 30px;
    font-size: 50px;
    font-family: var(--cursiveFamily);
    color: var(--purpleColor);
    text-align: center;
    font-weight: 600
}

/* .event-header span{
    font-family:var(--cursiveFamily);
    font-size: 50px;
    font-weight: 600
} */

/* .event-schedule{
    background:var(--primaryColor);
    padding: 15px 0px;
} */
.event-schedule-text {
    font-size: 30px;
    font-family: var(--textFamily);
    color: var(--blackColor);
    padding-top: 20px;
}

.event-schedule-container {
    display: flex;
    justify-content: space-between;
}

.event-display {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 38px;
}

/* .upcoming-event-display{
    padding: 20px 0px;
} */
.event-link {
    text-decoration: none;
}

.event-title {
    color: #fff;
    font-family: var(--textFamily);
    font-size: 22px;
    transition: 0.4s all ease;
}

.event-title:hover {
    color: var(--purpleColor);
}

.event-date,
.event-location {
    color: var(--blackColor);
    font-family: var(--textFamily);
    font-size: 17px;
    padding-bottom: 3px;
    line-height: 30px;
}

.searchbyyear {
    padding: 0px 0px 30px 0px;
}

.searchbyyear-title {
    font-size: 15px;
    color: var(--blackColor);
    font-family: var(--textFamily)
}

.searchbyyear input {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid var(--purpleColor);
    transition: 0.4s all ease;
    color: var(--blackColor);
    font-size: 18px;
    font-family: var(--textFamily);
}

.searchbyyear input:focus {
    outline: none;
    border: 1px solid var(--primaryColor);
}

.searchbyyear input::-webkit-outer-spin-button,
.searchbyyear input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.searchbyyear input[type=number] {
    -moz-appearance: textfield;
}

.searchbyyear input[type=number]:hover {
    -moz-appearance: textfield;
}