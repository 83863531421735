.success-bg{
    display: flex;
    width:100%;
    padding: 40px 0px;
}
.suceessimg-container{
    width: 70%!important;
    overflow: hidden;
}
.success-img img{
    width:110%;
    height: 500px;
    object-fit: cover;
    position: relative;
}
.success2bg{
    background: var(--purpleColor);
    height: 400px;
    width: 50%;
    position: relative;
    left:-40px;
    margin-top:40px;
    padding: 30px;
    color:#fff;
    font-family: var(--textFamily);
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    opacity: 0.7;
}
.success2bg h1{
    color:#fff!important;
}
.success2bg .success-text{
    font-size: 18px;
    padding: 30px 0px;
    text-align: justify;
    line-height: 30px;
}
.success-link{
    font-size: 26px;
    text-decoration: none;
    color:#fff;
    transition: 0.4s all ease;
}
.success-link:hover{
    color: var(--primaryColor);
}
.success-link span{
    padding-left: 20px;
}

/* this is the css for the stories slide */
.slick-slide {
    padding: 0 7px; 
    box-sizing: border-box;
}
.slider-testimony{
    padding: 45px 0px 0px 0px;
    background: rgb(240, 238, 238);
    margin-top: 50px;
}
.Testimony-container{
    width: 100%;
    height:100%;
}
.slider-container{
    width: 100%;
    margin-right: 20px;
}
.slide-img{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slide-img img{
    object-fit: cover;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    z-index: 20;
}
.content-container{
    position: relative;
    width: 100%;
    background: #fff;
    top: -80px;
    padding: 90px 5px 20px 10px;
    box-shadow: rgba(139, 1, 98, 0.24) 0px 3px 8px;
    border-radius: 10px;
    height: auto;
    font-family: var(--textFamily);
    color: var(--blackColor);
    transition: 0.4s all ease;
}
.content-container:hover{
    transform: scale(1.04);
    opacity: 0.7;
    background: linear-gradient(180deg, rgba(224, 211, 25, 0.877), rgba(220, 236, 73, 0.582));
}
.content-container .names{
    text-align: center;
    font-weight: 600;
    font-family: var(--textFamily);
    font-size: 20px;
    color: var(--purpleColor);
}
.content-container .location{
    text-align: center;
    font-style: italic;
    font-size: 16px;
}
.content-container .testimony{
    text-align: center;
    padding-top: 40px;
    line-height: 30px;
}
.slider-arrow{
    background: none;
}
.slider-arrow button{
    background: none;
    position: relative;
    top: -70px;
}
.slider-arrow .arrow-btn{
    border: none;
    color: var(--purpleColor)
}
.slider-arrow .prev{
    padding-right: 60px;
}
@media screen and (max-width: 760px){
    .success-bg{
        display: block;
        padding: 10px 5px;
    }
    .suceessimg-container{
        width: 100%!important;
        display: block;
    }
    .success-img img{
        width:100%!important;
        height: 230px;
        object-fit: cover;
    }
    .success2bg{
        background: var(--purpleColor);
        height: 260px;
        width: 100%;
        left:0px;
        margin-top:20px;
        padding: 10px;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
        opacity: 0.7;
    }
    .success2bg .success-text{
        font-size: 15px;
        padding: 10px 0px;
    }
    .success-link{
        font-size: 23px;
        text-decoration: none;
        color:#fff;
        transition: 0.4s all ease;
    }
}
@media screen and (max-width: 850px) and (min-width: 760px){
    .success2bg .success-text{
        font-size: 16px;
        padding: 10px 0px;
    }
}