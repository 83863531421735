.contactpage{
    overflow:hidden;
    background: var(--mainBackground);
}
.contactpage .contact-text{
    padding: 40px 0px;
    text-align: justify;
    font-size: 17px;
    color:var(--blackColor);
    font-family: var(--textFamily);
    line-height: 30px;
}
.contactpage .contact-text p{
    line-height: 30px;
}
.contactpage .contact-nav{
    text-decoration: none;
    font-size: 18px;
    color:var(--purpleColor);
    font-family: var(--textFamily);
    transition: 0.4s all ease;
    font-weight: bold;
}
.contactpage .contact-nav:hover{
    color: var(--primaryColor);
}
.contactpage .contact-title{
    font-size: 30px;
    font-family: var(--textFamily);
    font-weight: bold;
    color:var(--purpleColor);
    text-transform: uppercase;
    padding-bottom: 30px;
}
.contact-details{
    padding-bottom: 40px;
}
.contact-field{
    color:var(--blackColor);
    font-size:14px;
    font-style: italic;
    padding-bottom: 20px;
}
.contact-field span{
    color:red;
}
.contactpage .contact-form{
    padding:10px 0px;
}
.contactpage .contact-form label{
    display: block;
    font-size: 18px;
    color:var(--blackColor);
    font-weight:600
}
.contactpage .contact-form label span{
    color:red;
    padding-left: 2px;
}
.contactpage .contact-form p{
    color:red;
    font-style: italic;
    font-size: 14px;
}
.contactpage .inputfield{
    width: 100%;
    height: 45px;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid var(--purpleColor);
    transition: 0.4s all ease;
    color:var(--blackColor);
    font-size:18px;
    font-family: var(--textFamily);
}
.contactpage .inputfield:focus, .contactpage .textarea:focus{
    outline: none;
    border: 1px solid var(--primaryColor);
}
.contactpage .textarea{
    width: 100%;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid var(--purpleColor);
    transition: 0.4s all ease;
}

/* form success css starts here */
.form-success{
    width: 100%;
    height: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 110px;
    z-index: 999;
}
.formsucess-container{
    width: 70%;
    height: 60%;
    border-radius: 12px;
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    background: linear-gradient(180deg, rgba(218, 233, 11, 0.445), rgba(241, 226, 7, 0.438)), url("https://res.cloudinary.com/hayteetech/image/upload/v1641830377/Joyagunbiade.com/formsucessbg_a4vqso.jpg");
    color:black;
    padding: 10px 20px;
    top: -100px;
    position: relative;
    object-fit:cover;
    background-repeat: no-repeat;
    background-size: cover ;
    background-attachment: fixed; 
}
.form-text{
    text-align: center;
    font-size: 40px;
    color: var(--blackColor);
    font-family: var(--textFamily);
    padding-bottom: 100px;
}
@media screen and (max-width: 800px){
    .form-text{
        font-size: 25px;
    }
}