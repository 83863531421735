.bootstrapcarousel{
    height: 400px;
}
.carousel-item img{   
    height:400px;
    background: no-repeat center center scroll;
    -webkit-background-size:cover;
    background-size: cover;
}
.slider-arrow{
    font-size: 40px;
    color:var(--purpleColor);
    font-weight:700;
    background: var(--primaryColor)
}
.carousel-caption-left{
    text-align: left;
    top:40px;
    border-left: 7px solid #fff;
    height:300px;
    max-width: 500px;
}
.carousel-caption-right{
    position: relative;   
}
.carousel-lineright{
    position: relative;
    text-align: right;
    height:300px;
    border-right:7px solid #fff;
    max-width: 500px;
    left: 45%!important;
    padding-right:15px;
}
.carousel-line{
    padding-left: 20px;
}
.quote{
    font-size: 90px;
    color:#fff;

}
.quote-right{
    color: var(--purpleColor);
   
}
.quote-icon{
    padding: 10px 0px;
}
.carousel-caption p{
    font-size: 25px;
    font-family: var(--textFamily);
}
@media screen and (max-width:850px){
    .carousel-caption{
        display: block!important;
    }
    .bootstrapcarousel{
        height: 300px!important;
    }
    .carousel-item img{   
        height:300px!important;   
    }

    .carousel-lineright{
        position: relative;
        text-align: right;     
        max-width: 500px;
        left: 5%!important;
        height:250px;
        top: 10px;
    }
    .carousel-caption-left{
        text-align: left;
        top:10px;
        border-left: 5px solid #fff;
        height:250px;
        max-width: 300px;
    }
    .quote{
        font-size:40px;
    }
    .carousel-caption p{
        font-size: 17px;
        font-family: var(--textFamily);
    }
  
}

    
