.podcast-container{
    background: linear-gradient(to right, transparent , rgba(66, 66, 66, 0.842)), url("../../images/podcast.jpg");
    background-repeat:no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    /* height: 70vh; */
    position: relative;
}

.podcast-bg{
    padding: 30px 0px;
    color: #fff;
    text-align: right;
    font-family: var(--textFamily);
}
.podcast-bg h1{
    color:#fff!important; 
    font-size: 45px;
    padding-bottom: 30px;
}
.podcast-bg p{
    line-height: 30px;
    font-size: 16px;
}
.spotify{
    padding-top: 30px;
}
.spotify img{
    width: 50%; 
    height: 80px;
    border: 2px solid var(--purpleColor);
    text-align: right;
    animation: blink 2s;
    animation-iteration-count: infinite;
}
@keyframes blink {
    0% {
        opacity: 1;
    }
    49% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 0.3;
    }
}

.podcast{
    margin: 50px 0px;
    text-align: center;
}
.podcast-content .card{
    margin-top: 25px;
    padding: 20px 5px;
    overflow: hidden;
    font-family: var(--textFamily);
}
.podcast-content .card h6{
    font-size: 17px;
    text-transform: capitalize;
    color: var(--blackColor);
}
.podcast-content .card p{
    font-size: 14px;
    color: var(--purpleColor)
}
.podcast-content .podcast-text{
    color:  var(--blackColor);
    font-size: 15px;
}
.podcast-content .audio{
    margin-top: 20px;
    width: 100%;
}

@media screen and (max-width: 960px){
    .spotify img{
        width: 50%; 
        height: 60px;
        border: 2px solid var(--purpleColor);
        text-align: right;
        animation: blink 3s;
        animation-iteration-count: infinite;
    }
}

@media screen and (max-width:760px){
    .spotify{
        padding-top: 10px;
    }
    .podcast-bg{
        padding-top: 15px;
    }
    .podcast-bg h1{
        color:#fff!important; 
        font-size: 45px;
        padding-bottom: 10px;
    }
    .podcast-bg p{
        line-height: 30px;
        font-size: 14px;
    }
}