.blogitem-background{
    background:url("https://res.cloudinary.com/hayteetech/image/upload/v1641829837/Joyagunbiade.com/joyblog_garybb.jpg");
    height:150px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.blogitem-background h1{
    color: #fff!important;
    padding-top: 20px;
}
.blogitem-background p{
    color:#fff;
    font-size: 20px;
    font-family: var(--textFamily);
}

.blog-item-container{
    padding: 40px 0px 30px 0px;
}
.blog-item-logo-text{
    padding-top: 10px;
    font-family:var(--cursiveFamily);
    font-size: 30px;
    color:var(--blackColor);
    font-weight: 700
}
.blog-item-logo span{
   display: block;
   position: relative;
   top:-10px;
   color:var(--purpleColor)
}

/* display of each bog starts here */
.each-blog-title{
    text-align: center;
    text-transform: uppercase;
    color: var(--blackColor);
    font-size:  30px;
    font-weight: 600;
    padding-top: 10px;
}
.each-blog-quote{
    font-size: 20px;
    position: relative;
    top: -10px;
    color: var(--purpleColor);   
}
.display-each-img{
    padding: 30px 0px;
}
.display-each-img img{
    object-fit: contain;
    width: 100%;
    height: 250px;
    border-radius: 15px!important;
}
.display-each-blog p{
    line-height: 30px;
    text-align: justify;
    color: var(--blackColor);
    font-weight: 550
}
.blog-item-comments{
    padding-bottom: 40px;
}
.blog-item-comments h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    font-family: var(--textFamily);
    color: var(--blackColor);
    font-weight: 600;
    padding-bottom: 30px;
}


.blogItem-sidebar{
    padding: 40px 0px 30px 0px;
}
.blogItem-sidebar a{
    text-decoration: none;
}
.blogItem-sidebar-instagram{
    background: var(--purpleColor);
    color:#fff;
    font-family: var(--textFamily);
    width: 70%;
    place-items: center;
    padding: 10px 3px;
    border-radius: 3px;
    transition:0.4s all ease;
}
.blogItem-sidebar-instagram:hover{
    background: var(--primaryColor);
    color:#fff;
}
.blogItem-sidebar-instagram span{
    padding-left: 10px;
}
.blogItem-sidebar-recent{
    padding-top: 30px;
    font-family: var(--textFamily);
    color:var(--blackColor);
    font-size: 20px;
    text-transform: uppercase;
}

/* comment section */
.comment-form{
    width: 100%;
    margin-bottom:20px;
}
.comment-form .inputfield{
    width: 100%;
    height: 45px;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid var(--purpleColor);
    transition: 0.4s all ease;
    color:var(--blackColor);
    font-size:18px;
    font-family: var(--textFamily);
}
.comment-form .inputfield:focus, .comment-form .textarea:focus{
    outline: none;
    border: 1px solid var(--primaryColor);
}
.comment-form .textarea{
    width: 100%;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid var(--purpleColor);
    transition: 0.4s all ease;
}
.blogitem-form{
    margin-bottom: 20px;
}