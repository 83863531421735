.footerpage{
    background: var(--primaryColor);
    padding: 40px 0px;
    color:var(--blackColor);
    font-family:var(--textFamily)
}
.footer-container{
    padding:0px 15px;
}
.footer-logo{
    font-family: var(--cursiveFamily);
    font-size: 20px;
}
.footer-logo-nav{    
    color:var(--purpleColor);
    font-size:30px;
    text-decoration: none;
}
.footer-about{
    max-width: 300px;
    line-height: 30px;
    font-size: 16px;
}
.footer-title{
    font-size: 16px;
    text-transform: uppercase;
    padding-bottom: 15px; 
    font-weight: 600;
    color:var(--purpleColor)
}
.footer-text ul{
    list-style: none;
    padding:0;
    margin:0;
}
.footer-text ul li{
    list-style: none;  
    padding-bottom: 8px;  
}
.footer-text .footer-link{
    text-decoration: none;
    color:var(--blackColor);
    font-size:16px;
    transition: 0.4s all ease;
    font-weight: 600
}
.footer-text .footer-link:hover{
    color:var(--purpleColor)
}
.footer-text .footer-mail{
    text-decoration: none;
    color: var(--purpleColor);
    font-size: 17px;
    font-style: italic; 
    transition: 0.4s all ease;
}
.footer-text .footer-mail:hover{
    color: var(--blackColor);
    font-style: normal;
}
.footer-social{
    display: flex;
    margin-top: 20px!important;
}
.footer-social li{
    padding-right:20px;
}
.footer-social .footer-navlink{
    background: var(--blackColor);
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 8px;
    color: var(--primaryColor);
    font-size: 14px!important;
    transition: 0.4s all ease;
}
.footer-social .footer-navlink:hover{
    background: var(--purpleColor);
    color:#fff;
}
.hr-line{
    width: 100%;
    border-top:2px solid var(--purpleColor);
}
.footer-copyright{
    font-family: var(--textFamily);
    font-size: 15px;
    color:var(--blackColor);
    font-weight: bold
}
.footer-copyright  span{
    padding-left: 20px;
}
.footer-copyright .footer-webmaster{
    color: var(--purpleColor);
    padding-left: 20px;
    text-decoration: none;
    transition:0.4s all ease;
}
.footer-copyright .footer-webmaster:hover{
    color:#fff;
}