.speakingcontent{
    padding: 30px 0px;
}
.speaking-picture{
    background:linear-gradient(to left, rgba(226, 11, 233, 0.192), rgba(7, 7, 6, 0.781)),url("https://res.cloudinary.com/hayteetech/image/upload/v1642600833/Joyagunbiade.com/aboutbanner_cxugun.jpg");
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.speaking-pictext{
    padding-top: 30px;
}
.speaking-pictext p{
    padding:10px 0px 20px 0px;
    max-width:420px;
    color:#fff;
    font-family: var(--textFamily);
    font-size:18px;
    line-height:30px;
    font-weight: 600;
}
.speaking-pictext .button{
    text-align: left!important;
    margin-bottom: 25px!important;
}

.speaking-pictext .button .speaking-arrow{
    font-weight: bold;
}
.speaking-header{
    color:#fff!important;
    max-width: 550px;
    font-size: 40px!important;
}
/* another section starts here */
.speakingBanner{
    padding: 50px 0px
}
.speaking-banner-title{
    padding: 0px 40px;
}
.speaking-banner-title {
    font-family:var(--textFamily);
    font-size:35px;
    color: var(--blackColor);
    line-height: 45px;
    font-weight: bold;
    text-transform: uppercase;
}
.speaking-banner p{
    font-family: var(--textFamily);
    line-height: 30px;
    color:var(--blackColor);
    font-size: 17px;
    text-align: justify;
}
.speaking-video{
    width: 100%;
    height:auto;
    position: relative;
    background: var(--primaryColor);
}
.speaking-video .react-player{
    height:70vh!important;
    width: 100%;
}

.speakingcontent .title{
    font-family: var(--cursiveFamily);
    font-size: 45px;
    color:var(--blackColor);
    font-weight: bold;
    padding-bottom: 30px;
}
.speakingcontent .title span{
    color:var(--purpleColor);
}
.speakingcontent p{
    margin-left: 20vw;
    max-width: 800px;
    color:var(--blackColor);
    font-size: 17px;
}

/* css for speaking form */

.speaking-container{
    margin:0 15vw;
    font-family: var(--textFamily);
    padding: 0px 0px 40px 0px;
}
.sendform{
    font-size: 23px;
    font-family: var(--textFamily);
    color:var(--blackColor);
    font-weight: 700;
    padding-bottom: 15px;
}
.speaking-container .speaking-text{
    font-size: 15px;
    padding-bottom: 15px;
    color: var(--blackColor);
}
/* form is here */
.speaking-container .speaking-text span{
    color:rgb(250, 34, 34);
    font-size: 20px;
}
.speaking-container .speaking-form{
    width: 100%;
    padding: 5px 0px 20px 0px;
}
.speaking-container .speaking-form label{
    display: block;
    font-size: 18px;
    color: var(--blackColor);
    font-weight: 600;
}
.speaking-container .speaking-form label span{
    color: rgb(250, 34, 34);
}
.speaking-container .speaking-form .inputfield{
    width: 100%;
    height: 45px;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid var(--purpleColor);
    transition: 0.4s all ease;
    color:var(--blackColor);
    font-size:18px;
    font-family: var(--textFamily);
}
.speaking-container .speaking-form p{
    font-size: 16px;
    font-style: italic;
    color: red;
}
::placeholder{
    color: #bdbdbd;
}
.speaking-container .speaking-form input:focus, .speaking-container .speaking-form textarea:focus,
.speaking-container .speaking-form select:focus{
    outline: none;
    border: 1px solid var(--primaryColor);
}
.speaking-container .speaking-form .textareafield{
    width: 100%;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid var(--purpleColor);
    transition: 0.4s all ease;
}
.submitButton{
    width: 100%;
    text-align:center;
    height: 50px;
    border:none;
    border-radius: 10px;
    background: var(--purpleColor);
    color: #fff;
    font-family: var(--textFamily);
    font-size:20px;
    text-transform: uppercase;
    transition: 0.4s all ease;
}
.submitButton:hover{
    background: var(--primaryColor);
    color: var(--purpleColor)
}

@media screen and (max-width:850px){
    .speaking-picture{
        width:100%;
        height:100%;
        background:linear-gradient(to left, rgba(226, 11, 233, 0.192), rgba(7, 7, 6, 0.781)),url("https://res.cloudinary.com/hayteetech/image/upload/v1642600833/Joyagunbiade.com/aboutbanner_cxugun.jpg");
        background-repeat: no-repeat;
        background-size: cover ;
        background-attachment: fixed;    
        background-position: center center;
        overflow:hidden
    }
    .speakingBanner{
        padding: 20px 0px 5px 0px
    }
    .speakingcontent .title{
        font-size: 35px;
        padding-bottom: 10px;
    }
    .speakingcontent p{
        margin-left: 0vw;
    }
    .speaking-pictext p{
        padding:10px 0px 20px 0px;
        max-width:420px;
        color:#fff;
        font-family: var(--textFamily);
        font-size:16px;
        line-height:30px;
        font-weight: 600;
    }
    .speaking-header{
        color:#fff!important;
        max-width: 100%;
        font-size: 35px!important;
    }

    .speaking-banner-title {
        font-size:25px;
        padding: 10px 10px;
    }
}